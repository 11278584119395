import React from "react";
import { useState, useEffect } from "react";
import { Accordion, Form, InputGroup, Nav, Tab } from "react-bootstrap";
import { BiCopy } from "react-icons/bi";
import Swal from "sweetalert2";
import useAuth from "../../../hooks/useAuth";
import useCompanyServices from "../../../services/companyServices";
import useBaseServices from "../../../services/baseServices";
import useAdminServices from "../../../services/adminServices";
import {
  first,
  second,
  third,
  Sfirst,
  Ssecond,
  Sthird,
  Sfourth,
  Sfive,
  Ssix,
  Sseven,
  Seight,
  Snine,
  Sten,
  Seleven,
  Stwelve,
  Sthirteen,
  Image9,
  Image10,
  Image12
} from "../../../assets/images/imgs/Images";


const VoxbayData = () => {
  const [key1, setKey1] = useState("tab1");
  const [accessToken, setAccessToken] = useState("");
  const { auth, setAuth } = useAuth();

  const { getCompanyProfile, putCompanyProfile, changeCompanyPrivacy } =
    useCompanyServices();
  const {
    getStaffProfile,
    getCompanyEmailConfig,
    postCompanyEmailConfig,
    putStaffProfile,
    putPasswordChange,
  } = useBaseServices();

  const {
    postAdminEmailConfig,
    getAdminEmailConfig,
    getAdminProfile,
    putAdminProfile,
  } = useAdminServices();

  useEffect(() => {
    getProfile();
  }, []);

  const WebForm = [
    { label: "access_token", data: accessToken },
    { label: "name", data: "<Name>" },
    { label: "email", data: "<Email Addres>" },
    { label: "mobile", data: "<Phone Number>" },
    { label: "interest", data: "<Interest>" },
    { label: "website_name", data: "<Website Name>" },
  ];

  const PabblyForm = [
    { label: "form_id", data: "Form ID" },
    { label: "page_id", data: "Page ID" },
    { label: "field_data", data: "Form Field Data" },
    { label: "name", data: "Full name" },
    { label: "email", data: "Email" },
    { label: "mobile", data: "Mobile" },
    {
      label: "access_token",
      data: accessToken,
    },
  ];

  const getProfile = async () => {
    try {
      var image;
      var response;
      if (auth.role === "Company") {
        response = await getCompanyProfile();
        setAccessToken(response.data.access_token);
      }
    } catch (err) {}
  };

  const handleCopy = (data) => {
    navigator?.clipboard?.writeText(data);
    Swal.fire({
      text: `Copied ${data.length > 25 ? "" : `'${data}'`} !!`,
      showConfirmButton: false,
      timer: 1000,
      position: "bottom-end",
      toast: true,
      background: "#000",
      color: "#FFF",
      width: "25rem",
    });
  };

  const plainTextLink =
    "oauth/access_token?grant_type=fb_exchange_token&client_id={your - app - id}&client_secret={your - app - secret}&fb_exchange_token={your - short - lived - user - access - token}";
  const plainTextLink1 =
    "me/accounts?access_token={your-long-lived-user-access-token}";
  return (
    <div className="company-list-container">
      <div className="company-list-card">
        <div className="company-list-header row mx-0">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Website Integration</Accordion.Header>
              <Accordion.Body>
                <div className="company-list-header row mx-0">
                  <Form.Group className="col-2">
                    <Form.Label className="h5 ffw-bold">Method</Form.Label>
                    <Form.Control value={"POST"} disabled />
                  </Form.Group>
                  <Form.Group className="col-10">
                    <Form.Label className="h5 ffw-bold">URL</Form.Label>
                    <InputGroup>
                      <Form.Control
                        value={
                          "https://crm-beta-api.vozlead.in/api/v2/lead/website/"
                        }
                        disabled
                        aria-describedby="request_url"
                      />
                      <InputGroup.Text
                        id="request_url"
                        className="mouse-pointer"
                        onClick={() =>
                          handleCopy(
                            "https://crm-beta-api.vozlead.in/api/v2/lead/website/"
                          )
                        }
                      >
                        <BiCopy size={"1.1rem"} />
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                </div>
                <Tab.Container activeKey={key1} onSelect={(k) => setKey1(k)}>
                  <div className="row mt-3">
                    {/* <div className="col-12">
                                            <Nav variant="tabs" className="flex-md-row flex-column">
                                                <Nav.Item className='pt-2 pe-md-1'>
                                                    <Nav.Link className='py-2  px-md-4 ' eventKey="tab1">Start</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </div> */}
                    <Tab.Content>
                      <Tab.Pane eventKey="tab1" className="">
                        <div className="followup-container">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th style={{ width: "4.5rem" }}>Sl No.</th>
                                <th>Key</th>
                                <th>Value</th>
                              </tr>
                            </thead>
                            <tbody>
                              {WebForm &&
                                WebForm.map((item, index) => {
                                  return (
                                    <>
                                      {item.label === "access_token" ? (
                                        <tr key={index}>
                                          <td>{index + 1}</td>
                                          <td className="">
                                            <div className="row mx-0 px-0 py-0 align-items-center">
                                              <div className="col-11 px-1">
                                                {item.label}
                                              </div>
                                              <div className="col-1 px-0">
                                                <div
                                                  className="btn px-1 py-1"
                                                  onClick={() =>
                                                    handleCopy(item.label)
                                                  }
                                                >
                                                  <BiCopy size={"1.3rem"} />
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                          <td className="">
                                            <div className="row mx-0 px-0 py-0 align-items-center">
                                              <div className="col-11 px-1">
                                                {item.data}
                                              </div>
                                              <div className="col-1 px-0 text-end">
                                                <div
                                                  className="btn px-1 py-1"
                                                  onClick={() =>
                                                    handleCopy(item.data)
                                                  }
                                                >
                                                  <BiCopy size={"1.3rem"} />
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      ) : (
                                        <tr key={index}>
                                          <td>{index + 1}</td>
                                          <td className="">
                                            <div className="row mx-0 px-0 py-0 align-items-center">
                                              <div className="col-11 px-1">
                                                {item.label}
                                              </div>
                                              <div className="col-1 px-0">
                                                <div
                                                  className="btn px-1 py-1"
                                                  onClick={() =>
                                                    handleCopy(item.label)
                                                  }
                                                >
                                                  <BiCopy size={"1.3rem"} />
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                          <td className="">
                                            <div className="row mx-0 px-0 py-0 align-items-center">
                                              <div className="col-12 ps-1">
                                                {item.data}
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                    </>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Tab.Container>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Pabbly Integration</Accordion.Header>
              <Accordion.Body>
                <Tab.Container activeKey={key1} onSelect={(k) => setKey1(k)}>
                  <div className="row mt-3">
                    <Tab.Content>
                      <div
                        className="w-100 d-flex flex-column p-0 m-0"
                        style={{ border: "1px solid gray", height: "auto" }}
                      >
                        <table
                          style={{
                            borderCollapse: "collapse",
                            textAlign: "left",
                          }}
                        >
                          <tr>
                            <td style={{ padding: "0" }}></td>
                            <td
                              style={{ padding: "5px" }}
                              className="ps-4 m-0 p-0"
                            >
                              <h3 className="text-center p-0 m-0 py-3">
                                Steps for Meta Lead Integration using Pabbly
                              </h3>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ padding: "5px" }} className="ps-3">
                              1.
                            </td>
                            <td style={{ padding: "5px" }} className="ps-5">
                              Create an account in Pabbly connect
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ padding: "5px", verticalAlign: "top" }}
                              className="ps-3"
                            >
                              2.
                            </td>
                            <td style={{ padding: "5px" }} className="ps-5">
                              Click ‘Create Workflow’ from the dashboard and
                              create the workflow in a folder
                              <img
                                src={first}
                                alt=""
                                width="100%"
                                height="300px"
                                style={{ objectFit: "contain" }}
                                className="pt-5"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ padding: "5px", verticalAlign: "top" }}
                              className="ps-3"
                            >
                              3.
                            </td>
                            <td style={{ padding: "5px" }} className="ps-5">
                              <img
                                src={second}
                                alt=""
                                width="100%"
                                height="300px"
                                style={{ objectFit: "contain" }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td style={{ padding: "5px" }} className="ps-3">
                              4.
                            </td>
                            <td style={{ padding: "5px" }} className="ps-5">
                              Select ‘Facebook Lead Ads’ from this
                            </td>
                          </tr>
                          <tr>
                            <td style={{ padding: "5px" }} className="ps-3">
                              5.
                            </td>
                            <td style={{ padding: "5px" }} className="ps-5">
                              Choose ‘New Lead(Instant)’ from the dropdown
                            </td>
                          </tr>
                          <tr>
                            <td style={{ padding: "5px" }} className="ps-3">
                              6.
                            </td>
                            <td style={{ padding: "5px" }} className="ps-5">
                              Connect with your Facebook account by adding a new
                              connection.
                            </td>
                          </tr>
                          <tr>
                            <td style={{ padding: "5px" }} className="ps-3">
                              7.
                            </td>
                            <td style={{ padding: "5px" }} className="ps-5">
                              Choose the page to connect and Select LeadGen
                              Forms as ‘All LeadGen Forms’
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ padding: "5px", verticalAlign: "top" }}
                              className="ps-3"
                            >
                              8.
                            </td>
                            <td style={{ padding: "5px" }} className="ps-5">
                              Select ‘API from Pabbly’ from the actions tab
                              <img
                                src={third}
                                alt=""
                                width="100%"
                                height="300px"
                                style={{ objectFit: "contain" }}
                                className="pt-5"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td style={{ padding: "5px" }} className="ps-3">
                              9.
                            </td>
                            <td style={{ padding: "5px" }} className="ps-5">
                              Select ‘Execute API Request’ from the event of the
                              action
                            </td>
                          </tr>
                          <tr>
                            <td style={{ padding: "5px" }} className="ps-3">
                              10.
                            </td>
                            <td style={{ padding: "5px" }} className="ps-5">
                              Set the Action Event Method as POST
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ padding: "5px", verticalAlign: "top" }}
                              className="ps-3"
                            >
                              11.
                            </td>
                            <td style={{ padding: "5px" }} className="ps-5">
                              Set Following Configurations
                              <div className="company-list-header row mx-0">
                                <Form.Group className="col-2">
                                  <Form.Label className="h5 ffw-bold">
                                    Method
                                  </Form.Label>
                                  <Form.Control value={"POST"} disabled />
                                </Form.Group>
                                <Form.Group className="col-10">
                                  <Form.Label className="h5 ffw-bold">
                                    URL
                                  </Form.Label>
                                  <InputGroup>
                                    <Form.Control
                                      value={
                                        "https://crm-beta-api.vozlead.in/api/v2/lead/meta/pabbly/"
                                      }
                                      disabled
                                      aria-describedby="request_url"
                                    />
                                    <InputGroup.Text
                                      id="request_url"
                                      className="mouse-pointer"
                                      onClick={() =>
                                        handleCopy(
                                          "https://crm-beta-api.vozlead.in/api/v2/lead/meta/pabbly/"
                                        )
                                      }
                                    >
                                      <BiCopy size={"1.1rem"} />
                                    </InputGroup.Text>
                                  </InputGroup>
                                </Form.Group>
                              </div>
                              <h4
                                className="m-0 p-0 pt-2"
                                style={{ fontSize: "15px" }}
                              >
                                Data :
                              </h4>
                              <div className="followup-container pt-2">
                                <table className="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th style={{ width: "4.5rem" }}>
                                        Sl No.
                                      </th>
                                      <th>Key</th>
                                      <th>Value</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {PabblyForm.map((item, index) => (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td
                                          className=""
                                          style={{ paddingRight: "15px" }}
                                        >
                                          <div className="row mx-0 px-0 py-0 align-items-center">
                                            <div className="col-11 px-1">
                                              {item.label}
                                            </div>
                                            <div className="col-1 px-0">
                                              <div
                                                className="btn px-1 py-1"
                                                onClick={() =>
                                                  handleCopy(item.label)
                                                }
                                              >
                                                <BiCopy size={"1.3rem"} />
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="">
                                          <div className="row mx-0 px-0 py-0 align-items-center">
                                            <div className="col-12 ps-1">
                                              {item.data}
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </Tab.Content>
                  </div>
                </Tab.Container>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Meta Integration</Accordion.Header>
              <Accordion.Body>
                <Tab.Container activeKey={key1} onSelect={(k) => setKey1(k)}>
                  <div className="row mt-3">
                    <Tab.Content>
                      <div
                        className="w-100 d-flex flex-column p-0 m-0"
                        style={{ border: "1px solid gray", height: "auto" }}
                      >
                        <table
                          style={{
                            borderCollapse: "collapse",
                            textAlign: "left",
                          }}
                        >
                          <tr>
                            <td style={{ padding: "0" }}></td>
                            <td
                              style={{ padding: "5px" }}
                              className="w-100 m-0 p-0"
                            >
                              <h3 className="text-center p-0 m-0 py-3 mb-3 text-danger bold">
                                VOZLEADCRM - Meta Integration
                              </h3>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ padding: "5px" }}
                              className="text-center w-100 m-0 p-0"
                              colSpan={2}
                            >
                              <h4 className="p-0 m-0 py-0 w-100 mb-4">
                                Step 1: Create a Facebook App for AppID and App
                                Secret
                              </h4>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ padding: "5px" }} className="ps-3">
                              1.
                            </td>
                            <td style={{ padding: "5px" }} className="ps-5">
                              Go to the Facebook Developers site.
                              <a href="https://developers.facebook.com/">
                                {"   "}https://developers.facebook.com/
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ padding: "5px", verticalAlign: "top" }}
                              className="ps-3"
                            >
                              2.
                            </td>
                            <td style={{ padding: "5px" }} className="ps-5">
                              Register with your Facebook account.
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ padding: "5px", verticalAlign: "top" }}
                              className="ps-3"
                            >
                              3.
                            </td>
                            <td style={{ padding: "5px" }} className="ps-5 ">
                              Click on "My Apps" in the top right corner.
                              <img
                                src={Sfirst}
                                alt=""
                                width="100%"
                                height="300px"
                                style={{ objectFit: "contain" }}
                                className="mt-3"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ padding: "5px", verticalAlign: "top" }}
                              className="ps-3"
                            >
                              4.
                            </td>
                            <td style={{ padding: "5px" }} className="ps-5">
                              Click on "Create App" and select the type of app
                              you want to create.
                              <img
                                src={Ssecond}
                                alt=""
                                width="100%"
                                height="300px"
                                style={{ objectFit: "contain" }}
                                className="mt-3"
                              />
                              <img
                                src={Sthird}
                                alt=""
                                width="100%"
                                height="300px"
                                style={{ objectFit: "contain" }}
                                className="mt-3"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td style={{ padding: "5px" }} className="ps-3">
                              5.
                            </td>
                            <td style={{ padding: "5px" }} className="ps-5">
                              In your app's dashboard, go to "Settings" -
                              "Basic".
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ padding: "5px", verticalAlign: "top" }}
                              className="ps-3"
                            >
                              6.
                            </td>
                            <td style={{ padding: "5px" }} className="ps-5">
                              Copy your App ID and App Secret.
                              <img
                                src={Sfourth}
                                alt=""
                                width="100%"
                                height="300px"
                                style={{ objectFit: "contain" }}
                                className="mt-3 mb-3"
                              />
                            </td>
                          </tr>
                          <tr className="">
                            <td
                              style={{ padding: "5px" }}
                              className="text-center w-100 m-0 p-0"
                              colSpan={2}
                            >
                              <h4 className="p-0 m-0 py-0 w-100 mb-4">
                                Step 2: Get a Long-Lived User Access Token
                              </h4>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ padding: "5px" }} className="ps-3">
                              1.
                            </td>
                            <td style={{ padding: "5px" }} className="ps-5">
                              Go to the Graph API Explorer.
                              <a href="https://developers.facebook.com/tools/explorer/">
                                {"   "}
                                https://developers.facebook.com/tools/explorer/
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ padding: "5px", verticalAlign: "top" }}
                              className="ps-3"
                            >
                              2.
                            </td>
                            <td style={{ padding: "5px" }} className="ps-5">
                              Select your app from the dropdown menu.
                              <img
                                src={Sfive}
                                alt=""
                                width="100%"
                                height="300px"
                                style={{ objectFit: "contain" }}
                                className="mt-3"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td style={{ padding: "5px", verticalAlign: "top" }} className="ps-3">
                              3.
                            </td>
                            <td style={{ padding: "5px" }} className="ps-5 lh-lg">
                              <div>
                                Click "Add a permission" and select
                                <ol>
                                  <li>
                                    Events Groups Pages
                                    <ul>
                                      <li>Ads_management</li>
                                      <li>Ads_read</li>
                                      <li>Business_management</li>
                                      <li>Pages_manage_ads</li>
                                      <li>Pages_manage_cta</li>
                                      <li>Pages_manage_metadata</li>
                                      <li>Pages_read_engagement</li>
                                      <li>Pages_show_list</li>
                                    </ul>
                                    <li>
                                    Other
                                    <ul>
                                      <li>Ads_management</li>
                                    </ul>
                                  </li>
                                  </li>
                                </ol>
                              </div>
                            </td>
                          </tr>
                          <tr></tr>
                          <tr>
                            <td style={{ padding: "5px" }} className="ps-3">
                              4.
                            </td>
                            <td style={{ padding: "5px" }} className="ps-5">
                              Click on "Get Token" - "Get User Access Token"
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ padding: "5px", verticalAlign: "top" }}
                              className="ps-3"
                            >
                              5.
                            </td>
                            <td style={{ padding: "5px" }} className="ps-5">
                              You will be redirected to Facebook to grant these
                              permissions.
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ padding: "5px", verticalAlign: "top" }}
                              className="ps-3"
                            >
                              6.
                            </td>
                            <td style={{ padding: "5px" }} className="ps-5">
                              After granting permissions, you will be redirected
                              back to the Graph API Explorer with your access
                              token.
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ padding: "5px", verticalAlign: "top" }}
                              className="ps-3"
                            >
                              7.
                            </td>
                            <td style={{ padding: "5px" }} className="ps-5">
                              Your user access token will be displayed in the
                              "Access Token" field in the Graph API Explorer.
                              <img
                                src={Ssix}
                                alt=""
                                width="100%"
                                height="300px"
                                style={{ objectFit: "contain" }}
                                className="mt-3"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ padding: "5px", verticalAlign: "top" }}
                              className="ps-3"
                            >
                              8.
                            </td>
                            <td style={{ padding: "5px" }} className="ps-5">
                              To make your access token to live long:
                              <img
                                src={Sseven}
                                alt=""
                                width="100%"
                                height="300px"
                                style={{ objectFit: "contain" }}
                                className="mt-3"
                              />
                              <p className="mt-3 text-left">
                                <i>
                                  Copy the below link into the second input box
                                  as shown in the screenshot:{" "}
                                  <a href="#">{plainTextLink}</a>. Replace{" "}
                                  {`{your - app - id}, {your - app - secret}, and {your - short - lived - user - access - token}`}{" "}
                                  with your app-id, app-secret, and
                                  short-lived-user-access-token. Then click the
                                  ‘Submit’ button.
                                </i>
                              </p>
                              <img
                                src={Seight}
                                alt=""
                                width="100%"
                                height="300px"
                                style={{ objectFit: "contain" }}
                                className="mt-3"
                              />
                              <p className="text-center w-100 mt-2">
                                Copy the access token without double quotes (“
                                “) and store it safely from the results.
                              </p>
                            </td>
                          </tr>
                          <tr className="">
                            <td
                              style={{ padding: "5px" }}
                              className="text-center w-100 m-0 p-0"
                              colSpan={2}
                            >
                              <h4 className="p-0 m-0 py-0 w-100 mb-4">
                                Step 3: Get a Long-Lived Page Access Token
                              </h4>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ padding: "5px", verticalAlign: "top" }}
                              className="ps-3"
                            >
                              1.
                            </td>
                            <td style={{ padding: "5px" }} className="ps-5">
                              Use the long-lived user access token to get a page
                              access token by requesting the endpoint:
                              <img
                                src={Sthirteen}
                                alt=""
                                width="100%"
                                height="300px"
                                style={{ objectFit: "contain" }}
                                className="mt-3"
                              />
                              <p className="mt-3 text-left">
                                <i>
                                  Copy the below link into the second input box
                                  as shown in the screenshot:{" "}
                                  <a href="#">{plainTextLink1}</a>. Replace{" "}
                                  {`{your-long-lived-user-access-token}`} with
                                  the access token you get in the last step.
                                  Then click the ‘Submit’ button.
                                </i>
                              </p>
                              <img
                                src={Snine}
                                alt=""
                                width="100%"
                                height="300px"
                                style={{ objectFit: "contain" }}
                                className="mt-3"
                              />
                              <p className="text-center w-100 mt-2">
                                In the response, Copy the access_token
                                highlighted in the blue box above, This will be 
                                <span className="fw-bold"> your Page Access Token</span>.
                              </p>
                              <p>
                               - Now Click on Tools at the top of this page and select ‘Access Token Debugger’
                              </p>
                              <p> - Paste your Page Access Token on this Page.</p>
                              <img
                                src={Image10}
                                alt=""
                                width="100%"
                                height="300px"
                                style={{ objectFit: "contain" }}
                                className="mt-3"
                              />
                              <p>
                              Now scroll down, and you will find a button ‘extend access token, press the button
                              </p>
                              <img
                                src={Image9}
                                alt=""
                                width="100%"
                                height="300px"
                                style={{ objectFit: "contain" }}
                                className="mt-3"
                              />
                              <p>
                              Now copy the green color token that is generated 
                              </p>
                              <img
                                src={Image12}
                                alt=""
                                width="100%"
                                height="300px"
                                style={{ objectFit: "contain" }}
                                className="mt-3"
                              />

                              <p>That’s your <span className="fw-bold">long-live access token</span>.</p>
                            </td>
                          </tr>
                          <tr className="">
                            <td
                              style={{ padding: "5px" }}
                              className="text-center w-100 m-0 p-0"
                              colSpan={2}
                            >
                              <h4 className="p-0 m-0 py-0 w-100 mb-4">
                                Step 4: Create a Page in CRM
                              </h4>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ padding: "5px", verticalAlign: "top" }}
                              className="ps-3"
                            >
                              1.
                            </td>
                            <td style={{ padding: "5px" }} className="ps-5">
                              Get ‘Page ID’ from your Facebook Page, as shown in
                              the screenshot below: Face
                              <img
                                src={Sten}
                                alt=""
                                width="100%"
                                height="300px"
                                style={{ objectFit: "contain" }}
                                className="mt-3"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ padding: "5px", verticalAlign: "top" }}
                              className="ps-3"
                            >
                              2.
                            </td>
                            <td style={{ padding: "5px" }} className="ps-5">
                              Log in to the Company account in Vozlead CRM
                            </td>
                          </tr>

                          <tr>
                            <td
                              style={{ padding: "5px", verticalAlign: "top" }}
                              className="ps-3"
                            >
                              3.
                            </td>
                            <td style={{ padding: "5px" }} className="ps-5">
                              Settings – Meta Pages
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ padding: "5px", verticalAlign: "top" }}
                              className="ps-3"
                            ></td>
                            <td style={{ padding: "5px" }} className="ps-5">
                              <img
                                src={Seleven}
                                alt=""
                                width="100%"
                                height="300px"
                                style={{ objectFit: "contain" }}
                                className="mt-3"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ padding: "5px", verticalAlign: "top" }}
                              className="ps-3"
                            >
                              4.
                            </td>
                            <td style={{ padding: "5px" }} className="ps-5">
                              Provide your ‘Page Access Token’
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ padding: "5px", verticalAlign: "top" }}
                              className="ps-3"
                            >
                              5.
                            </td>
                            <td style={{ padding: "5px" }} className="ps-5">
                              Click on ‘Connect Page’ in the top right corner
                            </td>
                          </tr>
                          <tr className="">
                            <td
                              style={{ padding: "5px" }}
                              className="text-center w-100 m-0 p-0"
                              colSpan={2}
                            >
                              <h4 className="p-0 m-0 py-0 w-100 mb-4">
                                Step 5: Connect Form in CRM
                              </h4>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ padding: "5px", verticalAlign: "top" }}
                              className="ps-3"
                            >
                              1.
                            </td>
                            <td style={{ padding: "5px" }} className="ps-5">
                              {`Settings –>  Active Forms`}
                              <img
                                src={Stwelve}
                                alt=""
                                width="100%"
                                height="300px"
                                style={{ objectFit: "contain" }}
                                className="mt-3"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ padding: "5px", verticalAlign: "top" }}
                              className="ps-3"
                            >
                              2.
                            </td>
                            <td style={{ padding: "5px" }} className="ps-5">
                              Provide the details given, and connect to your
                              form
                            </td>
                          </tr>
                          <tr className="mb-4">
                            <td
                              style={{ padding: "5px", verticalAlign: "top" }}
                              className="ps-3"
                            >
                              3.
                            </td>
                            <td style={{ padding: "5px" }} className="ps-5">
                              Lead from the form will be loaded into your lead
                              list
                            </td>
                          </tr>
                        </table>
                      </div>
                    </Tab.Content>
                  </div>
                </Tab.Container>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default VoxbayData;
